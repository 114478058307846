@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

.app-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.app-header {
    background: #add8e6;
    h1 {
        font-family: 'Lobster', cursive;
        font-size: 2.5rem;
    }
}